import React, { useState, useEffect } from 'react'
import moment from 'moment';
import "./styles.scss";
import ContactUs from "../../components/ContactUs";
import { Container, Row, Col, Button, Image, Table, Modal, Form } from 'react-bootstrap';
import ReCaptchaV2 from "react-google-recaptcha";
import { useAsyncCallback } from "react-async-hook";
import { useLocation } from "react-router-dom";
import { createJobApplicant, fetchJobs, uploadFile } from "../../utils/api";
import Footer from '../../components/Footer';

const Careers = () => {
    const [jobModalShow, showApplyModal] = useState(false);
    const [jobDescriptionModalShow, showJobDescriptionModal] = useState(false);
    const [selectedJobId, setJobId] = useState();
    const [selectedJob, setJobDetails] = useState();
    const [jobs, setJobs] = useState([]);

    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname])

    useEffect(() => {
        fetchJobs().then(res => setJobs(res.data.data.jobs))
    }, []);
    const applyNow = (jobId) => {
        showApplyModal(true)
        setJobId(jobId)
    }
    const viewDescription = (job) => {
        showJobDescriptionModal(true)
        setJobDetails(job)
        setJobId(job.id)
    }
    const handleApplyClick = () => {
        document.getElementById("section").scrollIntoView();
    }
    const text_truncate = (str, length, ending) => {
        if (length == null) {
            length = 50;
        }
        if (ending == null) {
            ending = '...';
        }
        if (str.length > length) {
            return str.substring(0, length - ending.length) + ending;
        } else {
            return str;
        }
    };
    return (
        <>
            <Container fluid className="career-container">
                <Row className='career-heading'>
                    <Col sm={8}>
                        <h3>
                            Power up your future while <br />
                            lighting up communities
                        </h3>
                        <p>The work you do with SBS makes a difference!</p>
                    </Col>                    
                </Row>
                <Row className="description-button">
                        <Col md="auto">
                            <Button variant="primary" className='btn'  onClick={handleApplyClick}>Apply Now</Button>
                        </Col>
                    </Row>
            </Container>
            <Container fluid className='section-two'>
                <Container fluid className='section-two-wrap'>
                    <Row className="justify-content-md-center">
                        <Col md={{ span: 8 }}>
                            <h1 className='section-two-header'>Join the SBS team and take the first step to a brighter career.</h1>
                        </Col>
                    </Row>
                    <Row className='icons-wrap'>
                        <Col sm className='d-flex text-center justify-content-center'>
                            <div>
                                <Image src="/icons/people.svg" className='icon'
                                />
                                <h6>
                                    Work for a company that <br />genuinely cares about its <br />employees
                                </h6>
                            </div>
                        </Col>
                        <Col sm className='d-flex text-center justify-content-center'>
                            <div>

                                <Image src="/icons/dollar.svg" className='icon'
                                />
                                <h6>

                                    Increase your earning <br />potential.
                                </h6>
                            </div>
                        </Col>
                        <Col sm className=' d-flex text-center justify-content-center'>
                            <div>

                                <Image src="/icons/security.svg" className='icon'
                                />
                                <h6>
                                    Receive the best field <br />training offered in the <br />industry
                                 </h6>
                            </div>
                        </Col>
                        <Col sm className='d-flex text-center justify-content-center'>
                            <div>
                                <Image src="/icons/app.svg" className='icon'
                                />
                                <h6>
                                    Complete inspections with our <br /> cutting-edge PoleTraq <br />inspection app.
                                 </h6>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className='section-three-wrap' id='section'>
                    <Row>
                        <Col>
                            <h1 className='section-three-header'>Job Openings</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Table striped bordered hover className='jobs-table'>
                            <thead>
                                <tr>
                                    <th>Name of the Position</th>
                                    <th>Location</th>
                                    <th>Job Description</th>
                                    <th>Date Uploaded</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    jobs.map((job) => {
                                        return (
                                            <tr key={job.id}>
                                                <td>{job.jobTitle}</td>
                                                <td>Location</td>
                                                <td className='job-description'>{text_truncate(job.jobDescription)}</td>
                                                <td>{moment(job.createdOn).format('L')}</td>
                                                <td className='apply-now-text' onClick={() => viewDescription(job)}>View Job Description</td>
                                                <td className='apply-now-text' onClick={() => applyNow(job.id)}>Apply Now</td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </Table>
                    </Row>
                </Container>
                <ContactUs></ContactUs>
                <Footer />
            </Container>
            <JobApplyFormModal
                show={jobModalShow}
                jobid={selectedJobId}
                onHide={() => showApplyModal(false)}
            />
            <ViewJobDescription
                show={jobDescriptionModalShow}
                onHide={() => showJobDescriptionModal(false)}
                selectedJob={selectedJob}
                jobid={selectedJobId}
                showApplyModal={showApplyModal}
                setJobId={setJobId}
            />
        </>
    )
}
function JobApplyFormModal(props) {
    const _reCaptchaRef = React.createRef();
    const [form, setForm] = useState({})
    const [uploadBtnText, setUploadBtnText] = useState('Upload')
    const [uploadToastShow, setUploadToastShow] = useState(false);
    const [file, setFileData] = useState()
    const [errors, setErrors] = useState({})
    const [validated, setValidated] = useState(false);
    const [reCaptchaResponse, setReCaptchaResponse] = useState(false)
    const [expired, setExpiredToken] = useState(false)
    const [captchaChecked, setCaptcha] = useState(false)
    const addJobApplicant = useAsyncCallback(createJobApplicant)
    const uploadResume = useAsyncCallback(uploadFile),
        onFormSubmit = e => {
            const errors = findFormErrors();
            e.preventDefault();
            e.stopPropagation();
            if ((errors
                && Object.keys(errors).length !== 0) || expired) {
                setErrors(errors)
            } else {
                setValidated(true);
                let jobData = {
                    jobId: props.jobid,
                    fullName: form.fullname,
                    emailAddress: form.email,
                    phoneNumber: form.phNumber,
                    address: form.address,
                    resumeUrl: form.resumeUrl,
                    linkedInUrl: form.linkedinUrl
                }
                let headers = {
                    'recaptchatoken': reCaptchaResponse,
                };
                addJobApplicant.execute(headers, jobData)
                    .then((res) => {
                        console.log(res)
                        if (res?.data?.data) {
                            setForm({});
                            setErrors(errors)
                            setValidated(false);
                            handleClose();
                        }
                    });
            }
        }

    const handleClose = () => props.onHide();
    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })
        // Check and see if errors exist, and remove them from the error object:
        if (!!errors[field]) setErrors({
            ...errors,
            [field]: null
        })
    }
    const setFile = (e) => {
        setFileData(e.target.files[0])
        setForm({
            ...form,
            'resumeUrl': ''
        })
    }
    const findFormErrors = () => {
        const { fullname, phNumber, email, resumeUrl } = form
        const newErrors = {}
        // name errors
        if (!fullname || fullname === '') newErrors.fullname = 'First name cannot be blank!'
        else if (fullname.length > 30) newErrors.fullname = 'First name is too long!'
        if (!phNumber) newErrors.phNumber = 'Phone number not valid!'
        else if (phNumber) {
            const phRegex = /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/i;
            if (phRegex.test(phNumber) === false) {
                newErrors.phNumber = 'Phone number not valid!'
            }
        }
        // email errors
        if (!email || email === '') newErrors.email = 'Email cannot be blank!'
        else if (email) {
            const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (regex.test(email) === false) {
                newErrors.email = 'Email Address not valid!'
            }
        }
        //Resume
        if (!resumeUrl || resumeUrl === '') newErrors.resumeUrl = 'Please upload the resume'
        if (!captchaChecked) {
            newErrors.captcha = 'Recaptcha requires verification'
        }

        return newErrors
    }
    const handleUpload = () => {
        if (file) {
            setUploadBtnText('Uploading...')
            let formData = new FormData();
            formData.append('file', file);
            formData.append('type', 'job_application');
            formData.append('id', 1);
            uploadResume.execute(formData)
                .then((res) => {
                    setForm({
                        ...form,
                        'resumeUrl': res?.data?.data?.URL
                    })
                    setErrors({
                        ...errors,
                        resumeUrl: ''
                    })
                    setUploadToastShow(!uploadToastShow)
                    setUploadBtnText('Upload')
                });
        } else {
            setErrors({
                ...errors,
                resumeUrl: 'Please attach a resume to upload'
            })
        }
    };
    const onChangeCaptcha = (captcha) => {
        setCaptcha(true)
        setErrors({
            ...errors,
            captcha: ''
        })
        if (captcha === null) {
            setExpiredToken(true)
        }
        else {
            setExpiredToken(false)
        }
        setReCaptchaResponse(captcha)
    };
    return (
        <>
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter" className='modal-wrapper'>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Apply Now
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-modal">
                    <Form onSubmit={onFormSubmit} noValidate validated={validated}>
                        <Row className='form-row'>
                            <Form.Label className='required'>Full name</Form.Label>
                            <Form.Control placeholder="Full name" className='input-label' required
                                onChange={e => setField('fullname', e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.fullname}
                            </Form.Control.Feedback>
                        </Row>
                        <Row className='form-row'>
                            <Form.Label className='required'>Phone Number</Form.Label>
                            <Form.Control placeholder="Phone Number" className='input-label' required
                                onChange={e => setField('phNumber', e.target.value)}
                                type="number"
                                maxLength="10"
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.phNumber}
                            </Form.Control.Feedback>
                        </Row>
                        <Row className='form-row'>
                            <Form.Label>Address</Form.Label>
                            <Form.Control placeholder="Address" className='input-label'
                                onChange={e => setField('address', e.target.value)} />
                        </Row>
                        <Row className='form-row'>
                            <Form.Label className='required'>Email</Form.Label>
                            <Form.Control placeholder="Email" className='input-label' required
                                onChange={e => setField('email', e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.email}
                            </Form.Control.Feedback>
                        </Row>
                        <Row className='form-row'>
                            <Form.Label>LinkedIn url</Form.Label>

                            <Form.Control placeholder="LinkedIn url" className='input-label'
                                onChange={e => setField('linkedinUrl', e.target.value)}
                            />
                        </Row>
                        <Form.Label className='required'>Upload Resume</Form.Label>
                        <Row className='justify-content-between upload-wrap'>
                            <Col sm={8}><Form.Control type="file"
                                accept="application/pdf,application/msword,
                                application/vnd.openxmlformats-officedocument.wordprocessingml.document" className='resume-label' onChange={(e) => setFile(e)} /></Col>
                            <Col sm={4} className='text-right'>
                                <Button onClick={handleUpload} className="btn upload-btn" disabled={(file ? false : true) || (uploadBtnText === 'Uploading...' ? true : false)}>
                                    {uploadBtnText}
                                </Button></Col>
                        </Row>
                        <Form.Control.Feedback type="invalid">
                            {errors.resumeUrl}
                        </Form.Control.Feedback>
                        <Container fluid className='captcha-wrap'>
                            <ReCaptchaV2
                                ref={_reCaptchaRef}
                                sitekey="6Lf0_2scAAAAAACfDHx5Zvu6fwCtPd4zP_RsZlLk"
                                onChange={onChangeCaptcha}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.captcha}
                            </Form.Control.Feedback>
                        </Container>
                        <Button variant="primary" type="submit" className='submit-btn'>
                            Submit
                        </Button>
                        <br />
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}
function ViewJobDescription(props) {
    let job = props.selectedJob || {};
    const applyNow = (jobId) => {
        props.showApplyModal(true)
        props.setJobId(jobId)
        props.onHide()
    }
    return (
        <>
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter" className='modal-wrapper'>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Apply Now
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-modal">
                    <Container className='section-four-wrap'>
                        <Row>
                            <h3>Name of the Position</h3>
                        </Row>
                        <Row>
                            <p>{job.jobTitle}</p>
                        </Row>
                        <Row>
                            <h3>Location</h3>
                        </Row>
                        <Row>
                            <p>{job.jobTitle}</p>
                        </Row>
                        <Row>
                            <h3>Job Description</h3>
                        </Row>
                        <Row>
                            <p>{job.jobDescription}</p>
                        </Row>
                        <Row>
                            <h3>Date Uploaded</h3>
                        </Row>
                        <Row>
                            <p>{moment(job.createdOn).format('L')}</p>
                        </Row>
                        <Row className="description-button">
                        <Col md="auto">
                            <Button variant="primary" className='btn' onClick={() => applyNow(job.id)}>Apply Now</Button>
                        </Col>
                    </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default Careers
