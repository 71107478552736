import React, { useState } from 'react'
import "./styles.scss";
import ContactUs from "../../components/ContactUs";
import { Link } from 'react-scroll'
import { Container, Row, Col, Image, ListGroup } from 'react-bootstrap';
import { servicesMockData } from './mockData';
import Footer from '../../components/Footer';

const Services = () => {
    let servicesData = servicesMockData.services;
    // const [selectedNavId, setNavId] = useState();
    const [selectedNavItem, setNavItem] = useState(servicesData[0]);

    const onNavChange = (id) => {
        let servicesDetails = servicesMockData.services;
        // eslint-disable-next-line array-callback-return
        servicesDetails.map((item, index) => {
            if (item.id === id) {
                setNavItem(item)
            }
        });
    }

    return (
        <>
            <Container className="services-container justify-content-center ">
                <Row className='services-heading'>
                    <Col sm={5}>
                        <h3>
                            Our Services
                        </h3>
                        <p>We are committed to deliver excellence in everything we do. Our staff knows that the only way we can become a successful business is by making you happy. Feel confident that you are receiving exceptional service by choosing SBS Inspections, Inc.</p>
                    </Col>
                    <Col sm={7} className='image-wrap'>
                        <Image src="/images/IMG_6082.png" className='image'
                            responsive />
                    </Col>
                </Row>
                <Row className='services-content'>
                    <Col sm={4}>
                        <ListGroup as="ul" className='list'>
                            {
                                servicesData.map((item, index) => {
                                    return (
                                        <ListGroup.Item as="li" className={['list-group', selectedNavItem.id === item.id ? 'active' : '']} action onClick={() => onNavChange(item.id)}>
                                            <Link to={item.id} spy={true} className='link'   onClick={() => onNavChange(item.id)}>
                                                {item.title}
                                            </Link>
                                        </ListGroup.Item>
                                    )
                                })
                            }
                        </ListGroup>
                    </Col>
                    <Col sm={8}>
                        <div id={selectedNavItem.id} className='service-content-wrap'>
                            <h3>{selectedNavItem.title}</h3>
                            <p>
                                {selectedNavItem.content}
                            </p>
                        </div>
                    </Col>
                </Row>
                <ContactUs></ContactUs>
                <Footer />
            </Container>
        </>
    )
}

export default Services
