import React from 'react'
import "./styles.scss";
import ContactUs from "../../components/ContactUs";
import Footer from '../../components/Footer';
import { Container, Row, Col, Image } from 'react-bootstrap';

const Locations = () => {

    return (
        <>
            <Container fluid className="location-container">
                <Row className='location-heading'>
                    <Col sm={4}>
                        <h3>
                            Below is a map of where
                            we have ongoing operations
                        </h3>
                        <p>However, if you don’t see us in you state
please reach out as we often expand into new territories.</p>
                    </Col>
                </Row>
                <Row className='text-center justify-content-center'>
                    <Image src="/images/location.png" className='location-img'
                        responsive />
                </Row>
                <Row>
                    <Row className='info-wrap'>
                        <Col sm={5} className='text-center'>
                            <span>
                                <span>Don’t see us in your state? Reach out and we’ll</span>
                                <br/><span>find a way to reach you!</span>
                                <Image src="/icons/Path_1252.svg" className='arrow-bottom'
                                    responsive />
                            </span>
                        </Col>
                    </Row>
                </Row>
                <ContactUs></ContactUs>
                <Footer />
            </Container>
        </>
    )
}

export default Locations
