import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import "./styles.scss";
import { useHistory } from "react-router-dom";

const TimeLine = (props) => {
    const history = useHistory();
    function navigate(){
        history.push("/about-us");
    }
    return (
        <Container fluid className='timeline-wrap'>
            <Row className="justify-content-md-center">
                <Col md={{ span: 8 }}>
                    <h1 className='timeline-header'>The SBS Difference</h1>
                    <p className='timeline-text'>
                        SBS was founded to provide exceptional service quality and satisfaction to our customers. <br />
Combining hard work, honesty and transparency with decades worth of industry leading experience, SBS <br />provides trusted results that are always accurate and reliable.
                    </p>
                </Col>
            </Row>
            <Row className='icons-wrap'>
                <Col sm className='d-flex text-center justify-content-center icon-1'>
                    <div>
                        <Image src="/icons/mountain.svg" className='icon'
                            responsive />
                        <h6>
                            Servicing Extreme <br />Locations
                     </h6>
                    </div>
                </Col>
                <Col sm className='d-flex text-center justify-content-center icon-2'>
                    <div>

                        <Image src="/icons/badge.svg" className='icon'
                            responsive />
                        <h6>

                            High Quality <br />Work
                            </h6>
                    </div>
                </Col>
                <Col sm className=' d-flex text-center justify-content-center icon-3'>
                    <div>

                        <Image src="/icons/settings.svg" className='icon'
                            responsive />
                        <h6>
                            Excellent <br />Workmanship
                    </h6>
                    </div>
                </Col>
                <Col sm className='d-flex text-center justify-content-center icon-4'>
                    <div>

                        <Image src="/icons/accuracy.svg" className='icon'
                            responsive />
                        <h6>
                            Inspection<br /> Accuracy
                    </h6>
                    </div>
                </Col>
                <Col sm className='d-flex text-center justify-content-center icon-5'>
                    <div>
                        <Image src="/icons/hand.svg" className='icon'
                            responsive />
                        <h6>
                            We Never <br /> Compromise
                    </h6>
                    </div>
                </Col>
            </Row>
            <Container>
                <Row className="description-wrap">
                    <Col sm={8}>
                        <Row className='description-heading'>
                            <h3>
                                Extreme Locations
                        </h3>
                        </Row>
                        <Row className='description-text'>
                            <p>
                                Through the years and literally thousands of steel truss installations SBS has designed and developed the exclusive ALPHE technology which virtually allows us to access any trussable reject pole either in the field or in tight city confinements. Over the years even our competitors have contacted us to service poles they are not able to.
                         </p>
                        </Row>
                        <Row className='sub-text'>
                            <p>
                                This pole was in 4’ of water and 1’ of ice…which as opposed to replacement (would have been incredibly expensive if even possible) for the power company….. we were able to drive one of our ALPHE units across the lake, chainsaw through 1ft of ice then hoist an 18’ beam of steel into position and down through the ice and water and drove it into 6 ft of lake bed
                        </p>
                        </Row>
                    </Col>
                    <Col sm={4} className='text-center'>
                        <Image src="/images/IMG_5643.png"
                            responsive />
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="description-wrap-2">
                    <Col sm={2}>
                        <Image src="/icons/30.png" className='badge-icon'
                            responsive />
                    </Col>
                    <Col sm={10}>
                        <Row className='description-heading'>
                            <h3>
                                30 years of Unparalleled Customer Service
                        </h3>
                        </Row>
                        <Row className='description-text'>
                            <p>
                                We are committed to deliver excellence in everything we do. Our staff knows that the only way we can become a successful business is by making you happy. Feel confident that you are receiving exceptional service by choosing SBS Inspections, Inc.
                        </p>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Row className="justify-content-center text-center description-button">
                <Col md="auto">
                    <Button variant="primary" className='btn' onClick={navigate}>Learn More</Button>
                </Col>
            </Row>
        </Container>
    );
}

export default TimeLine;
