import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Locations from "./pages/Locations";
import Services from "./pages/Services";
import Header from "./components/Header";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import "./App.scss";
import Careers from "./pages/Careers";
import ScrollToTop from "react-scroll-to-top";
import PrivacyPolicy from "./pages/Policies/privacy-policy";

function App() {
  return (
    <BrowserRouter>
    <div className="App">
      <Header/>
      <ScrollToTop smooth style={{ height: '30px', width: '30px' }} />
      <Switch>        
					<Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/locations" component={Locations} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/careers" component={Careers} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
				</Switch>
    </div>
    </BrowserRouter>
  );
}

export default App;
