import React, { useState } from 'react';
import { Container, Row, Form, Col, Button, Alert } from 'react-bootstrap';
import _ from 'lodash';
import { submitContactUs } from '../../utils/api'
import "./styles.scss";

const ContactUs = (props) => {
  const [contactType, setContactType] = useState('enquiry');
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [companyName, setCompany] = useState(null);
  const [phone, setPhone] = useState(null);
  const [message, setMessage] = useState(null);
  const [isValid, setIsValid] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  const selectContactType = (cType) => setContactType(cType)

  const validate = () => {
    if (contactType && firstName && lastName && email && companyName && phone && message) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }

  const resetForm = () => {
    setFirstName(null);
    setLastName(null);
    setEmail(null);
    setCompany(null);
    setPhone(null);
    setMessage(null);
    setIsValid(null);    
  }

  const submit = async () => {
    try
    {
      if (isValid) {
        await submitContactUs({
          contactType, firstName, lastName, email, companyName, phone, message
        });
        setSubmitError(false);
        resetForm();
      } else {
        validate();
      }
    } catch(e) {
      console.log('Contact Us Error', e);
      setSubmitError(true)
    }
  }

  return (
    <Container fluid className='contact-us-wrap justify-content-center text-center'>
      <Row className="justify-content-center text-center description-button">
        <Col sm="6">
          <h3>
            Contact Us
          </h3>
          <Container fluid className='enquiry-wrapper'>
            <Row >
              <Col className={contactType === 'enquiry' ? 'active' : ''} onClick={() => selectContactType('enquiry')}>
                <span className='enquiry-text'>Enquiry</span>
              </Col>
              <Col className={contactType === 'message' ? 'active' : ''} onClick={() => selectContactType('message')}>
                <span className='enquiry-text'>Message</span>
              </Col>
            </Row>
          </Container>
          <Form className="text-left">
            <Row>
              <Col>
                <Form.Control placeholder="First Name" className='input-label' onChange={(e) => setFirstName(e.target.value)} />
                {
                  isValid === false && _.isEmpty(firstName) ?
                    <Form.Control.Feedback type="invalid">
                      Please provide your first name
                    </Form.Control.Feedback>
                    :
                    null
                }
                <br />
                <Form.Control placeholder="Email Address" className='input-label' onChange={(e) => setEmail(e.target.value)} />
                {
                  isValid === false && _.isEmpty(email) ?
                    <Form.Control.Feedback type="invalid">
                      Please provide your email address
                    </Form.Control.Feedback>
                    :
                    null
                }
                <br />
                <Form.Control placeholder="Company Name" className='input-label' onChange={(e) => setCompany(e.target.value)} />
                {
                  isValid === false && _.isEmpty(companyName) ?
                    <Form.Control.Feedback type="invalid">
                      Please provide the company you work for
                    </Form.Control.Feedback>
                    :
                    null
                }
                <br />
                <Form.Control placeholder="Phone Number" className='input-label' onChange={(e) => setPhone(e.target.value)} />
                {
                  isValid === false && _.isEmpty(phone) ?
                    <Form.Control.Feedback type="invalid">
                      Please provide your phone number
                    </Form.Control.Feedback>
                    :
                    null
                }
              </Col>
              <Col>
                <Form.Control placeholder="Last Name" className='input-label' onChange={(e) => setLastName(e.target.value)} />
                {
                  isValid === false && _.isEmpty(lastName) ?
                    <Form.Control.Feedback type="invalid">
                      Please provide your last name
                    </Form.Control.Feedback>
                    :
                    null
                }
                <br />
                <Form.Control
                  as="textarea"
                  placeholder="Message"
                  className='input-label'
                  style={{ height: '8.5rem' }}
                  onChange={(e) => setMessage(e.target.value)}
                />
                {
                  isValid === false && _.isEmpty(message) ?
                    <Form.Control.Feedback type="invalid">
                      Please provide your message
                    </Form.Control.Feedback>
                    :
                    null
                }
              </Col>
            </Row>
            <br />
            <Container fluid className='submit-btn-wrap'>
              {
                submitError === true ?
                  <Alert variant='danger'>
                    There was a problem submitting your {contactType}
                  </Alert>
                  :
                  submitError === false ?
                  <Alert variant='success'>
                    Your {contactType} has been submitted!
                  </Alert>
                  :
                  null
              }
              <Button variant="primary" type="button" className='submit-btn' onClick={submit}>
                Submit
              </Button>
            </Container>
          </Form>
        </Col>

      </Row>
    </Container>
  );
}

export default ContactUs;
