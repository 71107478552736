import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './style.scss'

export default class Footer extends Component {
    render() {
        return (
            <div className="page-footer">
                &copy; {(new Date()).getFullYear()} SBS Inspections &middot; &nbsp;
                <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
        )
    }
}
