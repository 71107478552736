import React from 'react';
import { Navbar, Container, Nav, Row, Col } from 'react-bootstrap';
import "./styles.scss";
import { Config } from '../../config.js';
import { useLocation } from 'react-router';
import { useHistory } from "react-router-dom";
const Header = (props) => {
  const history = useHistory();
  const location = useLocation();
  React.useEffect(() => {
  }, [location]);
  function navigateToCareers(){
    history.push("/careers");    
  }
  function navigateToHome(){
    history.push("/");
  }
  function navigateToContactus(){
    history.push("/");
    window.scrollTo(0,document.body.scrollHeight);
  }
  return (
    <div className='header'>
      <Container fluid className='header-wrap'>
        <Row className="justify-content-md-center header-first">
          <Col xs lg="3" className='header-first-title header-first-child'>
            Utility Pole Inspection Experts
          </Col>
          <Col md="auto" className='header-first-title'>
            <img src='/icons/Path_58 (4).svg' alt="flag" />
            <a href={`tel:${Config.sbsContactNo}`} className="header-last-child">
            Call us anytime {Config.sbsContactNo}
            </a>
          </Col>
        </Row>
        <Row>
          <Navbar expand="lg">
            <Container fluid className="justify-content-space-between">
              <Navbar.Brand className='home-btn' onClick={navigateToHome}>SBS Inspections Inc.</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav" >
                <Nav className="me-auto">
                  <Nav.Link href="/services">Services</Nav.Link>
                  <Nav.Link href="/careers">Careers</Nav.Link>
                  <Nav.Link href="/locations">Locations</Nav.Link>
                  <Nav.Link href="/about-us">About us</Nav.Link>
                  <Nav.Link onClick={navigateToContactus}>Contact</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col sm={5} className='badge-container'>
            <Container>
              <img src='/icons/30_years.svg' alt='flag' height="30"></img>
              <span className="text">Years Of industry excellence</span>
              <img src='/icons/30_years.svg' alt='flag' height="30"></img>
              {/* <Row>
                <Col></Col>
                <Col xs={9} className='text'></Col>
                <Col></Col>
              </Row> */}
            </Container>
          </Col>
          {location?.pathname !== '/' && location?.pathname !== '/careers' && <Col sm={5} className='info-container'>
            <Container>
              <Row onClick={navigateToCareers}>
                <span className='info-text'>
                  <span className='info-text-main'>We’re Hiring! </span>&nbsp; 
                  <span className='info-text-sub'> click here to apply!</span></span>
              </Row>
            </Container>
          </Col>}
        </Row>

      </Container>
    </div>
  );
}

export default Header;
