import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import "./styles.scss";
import { useLocation } from 'react-router';
import { useHistory } from "react-router-dom";
const Services = (props) => {
  const history = useHistory();
  const location = useLocation();
  React.useEffect(() => {
  }, [location]);
  function navigateToServices(){
    history.push("/services");
  }
  return (
    <Container fluid className='services-wrap justify-content-center text-center'>
      <Row className="justify-content-center services-text text-center">
        <h3>
        Our Services
        </h3>
      </Row>
      <Row>
        <Col sm className='justify-content-center text-center'>
          <Image src="/images/IMG_4057.png" className='img-main'
            responsive />
            <h6>
            Reinforcing using  <br />Steel Trusses
            </h6>
        </Col>
        <Col sm className='justify-content-center text-center'>
          <Image src="/images/IMG_2084.png" className='img-main'
            responsive />
          <h6>
            Full Inspection & <br />
            Treatment of Power Poles
          </h6>
        </Col>
        <Col sm className='justify-content-center text-center'>
          <Image src="/images/IMG_5897.png" className='img-main'
            responsive />
            <h6>
            Sound & Bore  <br />Inspections
            </h6>
        </Col>
      </Row>
      <Row className="justify-content-center services-btn-wrap">
        <Col md="auto">
          <Button variant="primary" className='services-btn' onClick={navigateToServices}>View All Services</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default Services;
