import axios from 'axios';
// import { history } from '../_helpers';
import { notification } from 'antd';

const axiosInstance = axios.create({
  baseURL: 'https://sbsapi.r2x.io/',
  // baseURL: 'http://localhost:3000/',
});

// axiosInstance.interceptors.request.use(async function (config) {
//   const user = localStorage.getItem('user');
//   if (user) {
//     config.headers.Authorization = `JWT ${JSON.parse(user).token}`;
//   }
//   return config;
// });
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    console.log(error)
    let message = error.response?.data?.msg || 'Something went wrong...'
    notification.error({
      message
    });
    if (401 === error.response?.status) {
      // history.push('/page500')
      // history.go(0)
    } else if (399 < error.response?.status && error.response?.status > 500) {
      // history.push('/page404')
      // history.go(0)
    }
    else {
      // history.push('/page500')
      // history.go(0)
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
