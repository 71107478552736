import React from 'react'
import "./styles.scss";
import HomeCarousel from "../../components/Carousel";
import Services from "../../components/Services";
import TimeLine from "../../components/TimeLine";
import ContactUs from "../../components/ContactUs";
import { useHistory } from "react-router-dom";
import Footer from '../../components/Footer';


import { Image, Container, Row, Col } from 'react-bootstrap';

const Home = () => {
  const history = useHistory();
    function navigate(){
        history.push("/careers");
        window.scrollTo(0, 0);
    }
    const updateCurrentSlide = (id) => {
        //
    }
    return (
        <>
            <Container fluid className="home-container">
                <HomeCarousel updateCurrentSlide={updateCurrentSlide} className='carousel'></HomeCarousel>
                <Row className='info-container' onClick={navigate}>
                    <Col>
                        <Image src="/icons/forward.png" className='img-main'
                            responsive />
                        <span className='info-text'>
                            <span className='info-text-main'>We’re Hiring!</span>&nbsp; 
                            <span className='info-text-sub'>click here to apply!</span></span>
                        <Image src="/icons/backward.png" className='img-main'
                            responsive />
                    </Col>
                </Row>
            </Container>
            <Container fluid className='section-2'>
                <TimeLine></TimeLine>
                <Services></Services>
                <ContactUs></ContactUs>
            </Container>    
            <Footer />        
        </>

    )
}

export default Home
