import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom";
import "./styles.scss";
import Footer from '../../components/Footer';


import { Container, Row, Col } from 'react-bootstrap';

const AboutUs = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname])
    return (
        <>
            <Container fluid className="about-container">
                <Row className='about-us-heading'>
                    <Col sm={8}>
                        <h2>
                            Celebrating 25 years of
                            industry excellence
                        </h2>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='about-us-content'>
                <Row className="description-wrap">
                    <Col sm={5}>
                        <h3>About Our Founders</h3>
                        <p>Leveraging more than three-decades of utility pole industry experience, founder Kevin Lee started SBS Inspections to provide unparalleled customer service and industry-leading results.
25-years later, SBS continues to pride itself on delivering excellence to every customer it is fortunate to serve.</p>
                    </Col>
                    <Col sm={5}>
                        <Row className='description-heading'>
                            <h3>
                                Our values
                        </h3>
                        </Row>
                        <Row className='description-text'>
                            <p>
                                At SBS, we focus on the simple things that make the biggest positive impact to our customers. SBS is dedicated to honesty, transparency, and hard work. These values in combination with unmatched work quality allow SBS to deliver trusted results.
                        </p>
                        </Row>
                    </Col>
                </Row>
                <Row className="description-wrap-2">
                    <Col sm={5}>
                        <h3>Our team</h3>
                        <p>Each SBS field professional is rigorously trained over years (not months) to ensure that we provide accurate, detailed inspections and services to fortify your grid and extend the life of your utility assets.</p>
                    </Col>
                    <Col sm={5}>
                        <Row className='description-heading'>
                            <h3>
                                Our mission
                        </h3>
                        </Row>
                        <Row className='description-text'>
                            <p>
                                To deliver service and quality excellence and continue long-lasting relationships with every SBS customer.
                        </p>
                        </Row>
                    </Col>
                </Row>
                <Row className="footer-wrap">
                    <Container className="about-footer">
                        <Col sm={5}>
                            <Row className='description-heading'>
                                <h3>
                                    Our guarantee
                                 </h3>
                            </Row>
                            <Row className='description-text'>
                                <p>
                                    We understand the immense consequences of downed utility assets and its negative community impact. With this understanding, SBS is proud to guarantee that all inspections and services are methodically and meticulously performed with unmatched accuracy, precision, and reliability.
                        </p>
                            </Row>
                        </Col>
                    </Container>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export default AboutUs
