
export const servicesMockData = { 
    services: [
        {   
            "id":1,
            "title":"Full excavate/chemical retreat",
            "content":"Our team fully excavates around the base of the pole to determine the amount of decay in the pole. The decayed portion is then removed, and chemical treatments are applied to fortify the pole and increase its life."
        },
        {
            "id":2,
            "title":"Sound and Bore evaluation",
            "content":"SBS field experts inspect utility poles with a hammer to evaluate internal decay. Boring is then used to ascertain the location and magnitude of the decay."
        },
        {
            "id":3,
            "title":"Attachment inventory",
            "content":"SBS conducts a complete utility pole attachment inventory audit and visual attachment inspection."
        },
        {
            "id":4,
            "title":"Ground wire resistance tests",
            "content":"SBS experts test utility pole ground wires to provide accurate resistance readings."
        },
        {
            "id":5,
            "title":"Ground rod installation",
            "content":"Our team ensures proper installation of ground rods as per NEC standards to ensure protection of technicians and the electrical system."
        },
        {
            "id":6,
            "title":"Line height clearances ",
            "content":" Our team inspects utility pole attachments including clamps, bolts, cross arm supports and other equipment to determine if line height clearances adhere to NESC standards."
        },
        {
            "id":7,
            "title":"Animal guard shields",
            "content":"We install animal guard shields that help prevent disturbances in electrical systems and mitigate long-term outages. Simultaneously, animal guards help protect local wildlife."
        },
        {
            "id":8,
            "title":"Line height clearances",
            "content":"We install animal guard shields that help prevent disturbances in electrical systems and mitigate long-term outages. Simultaneously, animal guards help protect local wildlife."
        },
        {
            "id":9,
            "title":"GPS",
            "content":"SBS field experts place geolocation tags on each utility pole throughout your grid. This allows you to track each pole’s precise location with ease."
        },
        {
            "id":10,
            "title":"Steel Truss rejects",
            "content":"SBS increases the service life of your utility asset rejects by banding a steel truss to sound wood above ground, which extends to a depth below the groundline decay zone. Using only the highest-grade trusses from LWISNC.com and  its exclusive ALPHE technology,  SBS ensures access to even the most extreme locations."
        }
    ] 
};