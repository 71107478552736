import React from 'react'
import "./styles.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { useHistory } from "react-router-dom";
const HomeCarousel = (props) => {
    const history = useHistory();
    const location = useLocation();
    React.useEffect(() => {
    }, [location]);
    function navigateToContactus(){
        window.scrollTo(0,document.body.scrollHeight);
    }
    function navigateToServices(){
        history.push("/services");    
        window.scrollTo(0,0); 
    }
    function navigateToAboutUs(){
        history.push("/about-us");   
        window.scrollTo(0,0); 
      }
    return (
        <Carousel
            infiniteLoop={true}
            showThumbs={false}
            showArrows={true}
            showStatus={false}
            onChange={props.updateCurrentSlide}
            autoPlay={true}
            emulateTouch={true}
            interval={6000}
        >
            <Container fluid className='carousel-wrap'>
                <Row>
                    <Col sm={4} className='carousel-left'>
                        <Container fluid >
                            <Row className='carousel-row'>
                                <Col className='carousel-head'>Pole Inspection Experts at your service</Col>
                            </Row>
                            <Row>
                                <Col className='carousel-desc'> Trusted leaders in optimizing,
                                maintaining, and supporting
                            utility assets</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button variant="primary" className='carousel-btn' onClick={navigateToContactus}>Contact Us</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col sm={8} className='carousel-right'>
                        <Image src="/images/Header 1.svg" className='img-main'
                            responsive />
                    </Col>
                </Row>
            </Container>
            <Container fluid className='carousel-wrap'>
                <Row>
                    <Col sm={4} className='carousel-left'>
                        <Container fluid >
                            <Row>
                                <Col className='carousel-head'>Pole Inspection Experts at your service</Col>
                            </Row>
                            <Row>
                                <Col className='carousel-desc'>Fortify your power grids and
                                extend the life of your utility assets</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button variant="primary" className='carousel-btn' onClick={navigateToServices}>Our Services</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col sm={8} className='carousel-right'>
                        <Image src="/images/Header 2.svg" className='img-main'
                            responsive />
                    </Col>
                </Row>
            </Container>
            <Container fluid className='carousel-wrap'>
                <Row>
                    <Col sm={4} className='carousel-left'>
                        <Container fluid >
                            <Row>
                                <Col className='carousel-head'>Pole Inspection Experts at your service</Col>
                            </Row>
                            <Row>
                                <Col className='carousel-desc'>Better serve your communities
                            with well-maintained grids</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button variant="primary" className='carousel-btn' onClick={navigateToAboutUs}>About Us</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col sm={8} className='carousel-right'>
                        <Image src="/images/Group 149.png" className='img-main'
                            responsive />
                    </Col>
                </Row>
            </Container>
        </Carousel>
    )
}

export default HomeCarousel
